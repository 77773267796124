import {
  updateAdminMess,
  updateAdminMessDataTable,
  updateEmployeeMessDataTable,
  updateIsAddAdminMessClicked,
  updateIsAddMessClicked,
  updateIsEditAdminMessClicked,
  updateIsEditMessClicked,
  updateIsViewAdminMessClicked,
  updateIsViewMessClicked,
  updateMess,
  updateMessDataTable,
  updateNewAdminMess,
  updateNewMess,
} from "./mess.types";

const INITIAL_STATE = {
  mess: [],
  newMess: {},
  isAddMessClicked: null,
  isEditMessClicked: null,
  isViewMessClicked: null,
  messDataTable: {
    columns: [
      {
        label: "Menu Dish",
        field: "menuname",
        width: 270,
      },
      {
        label: "Units",
        field: "units",
        width: 200,
      },
      {
        label: "Day/Date",
        field: "menudate",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  },
  adminMess: [],
  newAdminMess: {},
  isAddAdminMessClicked: null,
  isEditAdminMessClicked: null,
  isViewAdminMessClicked: null,
  adminMessDataTable: {
    columns: [
      {
        label: "Employee Name",
        field: "employeeName",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        width: 200,
      },
      {
        label: "Day/Date",
        field: "date",
        width: 200,
      },
      {
        label: "Mess Status",
        field: "messStatus",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        width: 100,
      },
    ],
    rows: [],
  },
  employeeMessDataTable: {
    columns: [
      {
        label: "Employee Name",
        name: "employeeName",
        width: 270,
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        label: "Status",
        name: "status",
        width: 200,
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        label: "Day/Date",
        name: "date",
        width: 200,
        options: {
          filter: false,
          sort: false,
        },
       
      },
      {
        label: "Units",
        name: "units",
        width: 200,
        options: {
          filter: false,
          sort: false,
        },
       
      },
    
    ],
    rows: [],
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateNewMess:
      return {
        ...state,
        newMess: action.payload,
      };

    case updateMess:
      return {
        ...state,
        mess: action.payload,
      };

    case updateIsAddMessClicked:
      return {
        ...state,
        isAddMessClicked: action.payload,
      };

    case updateIsEditMessClicked:
      return {
        ...state,
        isEditMessClicked: action.payload,
      };
    case updateMessDataTable:
      return {
        ...state,
        messDataTable: action.payload,
      };
    case updateIsViewMessClicked:
      return {
        ...state,
        isViewMessClicked: action.payload,
      };
      case updateNewAdminMess:
        return {
          ...state,
          newAdminMess: action.payload,
        };
  
      case updateAdminMess:
        return {
          ...state,
          adminMess: action.payload,
        };
  
      case updateIsAddAdminMessClicked:
        return {
          ...state,
          isAddAdminMessClicked: action.payload,
        };
  
      case updateIsEditAdminMessClicked:
        return {
          ...state,
          isEditAdminMessClicked: action.payload,
        };
      case updateAdminMessDataTable:
        return {
          ...state,
          adminMessDataTable: action.payload,
        };
        case updateEmployeeMessDataTable:
          return {
            ...state,
            employeeMessDataTable: action.payload,
          };
      case updateIsViewAdminMessClicked:
        return {
          ...state,
          isViewAdminMessClicked: action.payload,
        };
    default:
      return state;
  }
};

export default reducer;
