export const updateNewEmployee = 'updateNewEmployee';
export const updateIsAddEmployeeClicked = 'updateIsAddEmployeeClicked';
export const updateEmployees = "updateEmployees";
export const updateIsEditEmployeeClicked = "updateIsEditEmployeeClicked";
export const updateEmployeesDataTable = "updateEmployeesDataTable";
export const updateEmployeesLeavesDataTable = "updateEmployeesLeavesDataTable";
export const updateEmployeesFilteredLeavesDataTable = "updateEmployeesFilteredLeavesDataTable";
export const updateIsViewEmpClicked = "updateIsViewEmpClicked";
export const setActiveKey="setActiveKey";
export const updateNewLeave = 'updateNewLeave';
export const updateIsViewLeaveClicked = "updateIsViewLeaveClicked";
export const updateItemInLeaves='updateItemInLeaves';
export const deleteItemInLeaves='deleteItemInLeaves';
export const leaveApplications="leaveApplications";
export const leaveSatus="leaveSatus";
export const nameFilter='nameFilter';
export const yearFilter="yearFilter";
export const publicHolidays='publicHolidays';
export const updateIsAddLeaveTypeClicked = 'updateIsAddLeaveTypeClicked';
export const updateIsEditLeaveTypeClicked = 'updateIsEditLeaveTypeClicked';
export const updateNewLeaveType = 'updateNewLeaveType';
export const updateLeavesTypeDataTable = 'updateLeavesTypeDataTable';
export const updateLeaveType = 'updateLeaveType';
export const updatePublicHolidayDataTable = "updatePublicHolidayDataTable";
export const updateEmployeeOnLeaveDataTable = "updateEmployeeOnLeaveDataTable";





