export const updateNewMess = 'updateNewMess';
export const updateIsAddMessClicked = 'updateIsAddMessClicked';
export const updateMess = "updateMess";
export const updateIsEditMessClicked = "updateIsEditMessClicked";
export const updateMessDataTable = "updateMessDataTable";
export const updateIsViewMessClicked = "updateIsViewMessClicked";
export const updateNewAdminMess = 'updateNewAdminMess';
export const updateIsAddAdminMessClicked = 'updateIsAddAdminMessClicked';
export const updateAdminMess = "updateAdminMess";
export const updateIsEditAdminMessClicked = "updateIsEditAdminMessClicked";
export const updateAdminMessDataTable = "updateAdminMessDataTable";
export const updateIsViewAdminMessClicked = "updateIsViewAdminMessClicked";
export const updateEmployeeMessDataTable = "updateEmployeeMessDataTable";
