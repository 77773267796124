export const attendanceData = 'attendanceData';
export const attendanceDataFiltered = 'attendanceDataFiltered';
export const updateAttendanceCorrectionDataTable = 'updateAttendanceCorrectionDataTable';
export const updateAttendance = 'updateAttendance';







