import { attendanceData, attendanceDataFiltered, updateAttendance, updateAttendanceCorrectionDataTable } from "./attendance.types";

const INITIAL_STATE = {
  attendance: [],

  attendanceDataTable: {
    columns: [
      {
        name: "name",
        label: "Employee",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "date",
        label: "Date",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "checkin",
        label: "Check-In",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "breakin",
        label: "Break-In",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "breakout",
        label: "Break-Out",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "breakTime",
        label: "Break-Time",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "checkout",
        label: "Check-Out",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "hours",
        label: "Hourse:Minutes",
        options: {
          filter: false,
          sort: false,
        },
      },
      {
        name: "action",
        label: "Action",
        options: {
          filter: false,
          sort: false,
        },
      },
    ],
    rows: [],
  },

  filteredAttendance: [],
  attendanceCorrectionDataTable: {
    columns: [
      {
        label: "Employee",
        field: "name",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        width: 150,
      },
      {
        label: "Check-In",
        field: "checkin",
        width: 150,
      },
      
      {
        label: "Check-Out",
        field: "checkout",
        width: 100,
      },
     
      {
        label: "Reason",
        field: "reason",
        width: 250,
      },
      {
        label: "Action",
        field: "action",
        width: 60,
      },
    ],
    rows: [],
  },
  attendance: [],
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case attendanceData:
      return {
        ...state,
        attendanceDataTable: action.payload,
      };
    case attendanceDataFiltered:
      return {
        ...state,
        filteredAttendance: action.payload,
      };
    case updateAttendanceCorrectionDataTable:
      return {
        ...state,
        attendanceCorrectionDataTable: action.payload,
      };
      case updateAttendance:
        return {
          ...state,
          attendance: action.payload,
        };
    default:
      return state;
  }
};

export default reducer;
