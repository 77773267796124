import {
  updateNewEmployee,
  updateIsAddEmployeeClicked,
  updateEmployees,
  updateIsEditEmployeeClicked,
  updateEmployeesDataTable,
  updateEmployeesLeavesDataTable,
  updateEmployeesFilteredLeavesDataTable,
  updateIsViewEmpClicked,
  setActiveKey,
  updateNewLeave,
  updateIsViewLeaveClicked,
  updateItemInLeaves,
  leaveApplications,
  deleteItemInLeaves,
  updateIsAddLeaveTypeClicked,
  updateNewLeaveType,
  updateLeaveType,
  updateIsEditLeaveTypeClicked,
  updateLeavesTypeDataTable,
  leaveSatus,
  nameFilter,
  yearFilter,
  publicHolidays,
  updatePublicHolidayDataTable,
  updateEmployeeOnLeaveDataTable
} from "./employees.types";

export const updateNewEmployeeAction = (params) => {
  return {
    type: updateNewEmployee,
    payload: params,
  };
};
export const leaveSatusAction=(params)=>{
  return {
    type:leaveSatus,
    payload:params
  }
}

export const publicHolidaysAction=(params)=>{
  return {
    type:publicHolidays,
    payload:params
  }
}
export const updateNewLeaveAction = (params) => {
  return {
    type: updateNewLeave,
    payload: params,
  };
};

export const updateItemInLeavesAction=(params)=>{
  return {
    type:updateItemInLeaves,
    payload:params
  }
}

export const deleteItemInLeavesAction=(params)=>{
  return {
    type:deleteItemInLeaves,
    payload:params
  }
}

export const nameFilterAction = (params) => {
  return {
    type: nameFilter,
    payload: params,
  };
};

export const yearFilterAction = (params) => {
  return {
    type: yearFilter,
    payload: params,
  };
};

export const setActiveKeyAction = (params) => {
  return {
    type: setActiveKey,
    payload: params,
  };
};

export const updateIsAddEmployeeClickedAction = (params) => {
  return {
    type: updateIsAddEmployeeClicked,
    payload: params,
  };
};

export const updateEmployeesAction = (params) => {
  return {
    type: updateEmployees,
    payload: params,
  };
};

export const leaveApplicationAction = (params) => {
  return {
    type: leaveApplications,
    payload: params,
  };
};

export const updateIsEditEmployeeClickedAction = (params) => {
  return {
    type: updateIsEditEmployeeClicked,
    payload: params,
  };
};
export const updateEmployeesDataTableAction = (params) => {
  return {
    type: updateEmployeesDataTable,
    payload: params,
  };
};

export const updateEmployeesLeavesDataTableAction = (params) => {
  return {
    type: updateEmployeesLeavesDataTable,
    payload: params,
  };
};

export const updateEmployeesFilteredLeavesDataTableAction = (params) => {
  return {
    type: updateEmployeesFilteredLeavesDataTable,
    payload: params,
  };
};

export const updateIsViewEmpClickedAction = (params) => {
  return {
    type: updateIsViewEmpClicked,
    payload: params,
  };
};
export const updateIsViewLeaveAction = (params) => {
  return {
    type: updateIsViewLeaveClicked,
    payload: params,
  };
};
export const updateIsAddLeaveTypeClickedAction=(params)=>{
  return {
    type:updateIsAddLeaveTypeClicked,
    payload:params
  };
};
export const updateIsEditLeaveTypeClickedAction=(params)=>{
  return {
    type:updateIsEditLeaveTypeClicked,
    payload:params
  };
};
export const updateNewLeaveTypeAction=(params)=>{
  return {
    type:updateNewLeaveType,
    payload:params
  };
};

export const updateEmployeeOnLeaveDataTableAction=(params)=>{
  return {
    type: updateEmployeeOnLeaveDataTable,
    payload: params
  };
};

export const updatePublicHolidayDataTableAction = (params) => {
  return {
      type: updatePublicHolidayDataTable,
      payload : params
  }
}
export const updateLeaveTypeAction=(params)=>{
  return {
    type:updateLeaveType,
    payload:params
  }
}