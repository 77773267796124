import { updateInterviews, updateInterviewDataTable } from "./interview.types";

const INITIAL_STATE = {
  interviews: [],
  interviewsDataTable: {
    columns: [
      {
        label: "Candidate Name",
        field: "candidateName",
        width: 270,
      },
      {
        label: "Interview Date",
        field: "interviewDate",
        width: 200,
      },
      {
        label: "Status",
        field: "status",
        width: 200,
      },
      {
        label: "Interviewer",
        field: "interviewer",
        width: 200,
      },
      {
        label: "Post Applied For",
        field: "jobTitle",
        width: 200,
      },
    ],
    rows: [],
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateInterviews:
      return {
        ...state,
        interviews: action.payload,
      };
    case updateInterviewDataTable:
      return {
        ...state,
        interviewsDataTable: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
