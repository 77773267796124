export const updateNewEmployeeEvaluation = 'updateNewEmployeeEvaluation';
export const updateIsAddEmployeeEvaluationClicked = 'updateIsAddEmployeeEvaluationClicked';
export const updateEmployeesEvaluation = "updateEmployeesEvaluation";
export const updateIsEditEmployeeEvaluationClicked = "updateIsEditEmployeeEvaluationClicked";
export const updateEmployeesEvaluationDataTable = "updateEmployeesEvaluationDataTable";
export const updateIsViewEmpEvaluationClicked = "updateIsViewEmpEvaluationClicked";
export const updateIsAddMemberDetailsClicked = "updateIsAddMemberDetailsClicked";
export const updateIsAddTeamDetailsClicked = " updateIsAddTeamDetailsClicked";
export const addEmployeeSelfEvaluations = " addEmployeeSelfEvaluations";
export const updateEmployeeSelfEvaluations = " updateEmployeeSelfEvaluations";
export const isAddSelfClicked = "isAddSelfClicked";
export const setEvaluationResults="setEvaluationResults";

export const setEvaluationHRResults="setEvaluationHRResults";
export const starOfTheMonthEmployee="starOfTheMonthEmployee"
export const addHrSelfEvaluations ="addHrSelfEvaluations";
export const updateIsAddHrEvaluationClicked="updateIsAddHrEvaluationClicked";
export const updateIsEditHrEvaluationClicked ="updateIsEditHrEvaluationClicked";
 export const updateHrEvaluationDataTable="updateHrEvaluationDataTable";
export const updateHrSelfEvaluations="updateHrSelfEvaluations";
export const updateHrEvaluation ="updateHrEvaluation";
export const setSelectedTeam ="setSelectedTeam"