import {
  updateNewEmployee,
  updateIsAddEmployeeClicked,
  updateEmployees,
  updateIsEditEmployeeClicked,
  updateEmployeesDataTable,
  updateEmployeesLeavesDataTable,
  updateEmployeesFilteredLeavesDataTable,
  updateIsViewEmpClicked,
  setActiveKey,
  updateNewLeave,
  updateIsViewLeaveClicked,
  updateItemInLeaves,
  leaveApplications,
  leaveSatus,
  updateIsAddLeaveTypeClicked,
  updateNewLeaveType,
  updateIsEditLeaveTypeClicked,
  updateLeaveType,
  updateLeavesTypeDataTable,
  nameFilter,
  yearFilter,
  deleteItemInLeaves,
  publicHolidays,
  updatePublicHolidayDataTable,
  updateEmployeeOnLeaveDataTable
} from "./employees.types";


const INITIAL_STATE = {
  employees: [],
  newEmployee: {},
  isAddEmployeeClicked: null,
  isEditEmployeeClicked: null,
  newType: {},
  leaveType:{},
  isAddLeaveTypeClicked: null,
  isEditLeaveTypeClicked: null,
  activeKey: 1,
  employeesDataTable: {
    columns: [{
      label: "",
      field: "image",
      width: 100,
    }, {
      label: "Employee Name",
      field: "name",
      width: 270,
    },
    {
      label: "Designation",
      field: "designation",
      width: 200,
    },
    {
      label: "Team",
      field: "teamname",
      width: 200,
    },
    {
      label: "Status",
      field: "status",
      width: 200,
    },
    {
      label: "Action",
      field: "action",
      width: 100,
    },
    ],
    rows: [],
  },

  filterLeavesRows: [],
  employeesLeavesDataTable: {
    columns: [{
      label: "Name",
      field: "name",
      width: 150,
    },
    {
      label: "Category",
      field: "category",
      width: 150,
    },
    {
      label: "Type",
      field: "leaveTypeShow",
      width: 100,
    },
    {
      label: "Leave Reason",
      field: "Reason",
      width: 250,
    },
  

    {
      label: "Dates",
      field: "daysLeaves",
      width: 180,
    },
   
    {
      label: "Status",
      field: "ApplicationStatusShow",
      width: 100,
    },
    {
      label: "Comment",
      field: "rejection_reason",
      width: 250,
    },
    {
      label: "Action",
      field: "action",
      width: 60,
    },
    ],
    rows: [],
  },


  employeesLeavesApplicationsDataTable: {
    columns: [{
      label: "Name",
      field: "name",
      width: 150,
    },
    {
      label: "Category",
      field: "category",
      width: 150,
    },
    {
      label: "Type",
      field: "leaveTypeShow",
      width: 100,
    },
    {
      label: "Leave Reason",
      field: "Reason",
      width: 250,
    },
  

    {
      label: "Dates",
      field: "daysLeaves",
      width: 180,
    },
    {
      label: "Available Leaves",
      field: "leaveQuota",
      width: 100,
    },
    {
      label: "Created Date",
      field: "createdAt",
      width: 100,
    },
   
    {
      label: "Action",
      field: "action",
      width: 60,
    },
    ],
    rows: [],
  },

  

  leavesTypeDataTable: {
    columns: [{
      label: "Personal Leaves",
      field: "personal_leaves",
      width: 100,
    },
    {
      label: "Compassionate Leaves",
      field: "compassionate_leave",
      width: 100,
    },
    {
      label: "Service Leaves",
      field: "service_leave",
      width: 100,
    },
    {
      label: "Paternity Leaves",
      field: "paternity_leaves",
      width: 100,
    },
    {
      label: "Bereavment Leaves",
      field: "bereavement_leave",
      width: 100,
    },
    {
      label: "Action",
      field: "action",
      width: 100,
    },
    ],
    rows: [],
  },
  employeeFilter: {},
  yearFilter: "",
  leavesStatus: [],
  leaveApplicationsData: [],
  isViewEmpClicked: null,
  publicsHolidays: [],
  publicHolidaysData: [],
  publicHolidaysDataTable: {
    columns: [{
      label: "Title ",
      field: "title",
      width: 100,
    },
    {
      label: "Public Holidays",
      field: "daysLeaves",
      width: 100,
    },
    {
      label: "Year",
      field: "year",
      width: 100,
    },
    {
      label: "Action",
      field: "action",
      width: 100,
    },
    ],
    rows: [],
  },
  employeeOnLeaveDataTable: {
    columns: [
      {
        label:"",
        key:"image"
      },
      {

      label: "Employee Name",
      key: "title",
      width: 150,
    },
   
    {
      label: "Type",
      key: "value",
      width: 100,
    },
   
    ],
    rows: [],
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateNewEmployee:
      return {
        ...state,
        newEmployee: action.payload,
      };

    case updateNewLeave:
      return {
        ...state,
        newLeave: action.payload,
      };

      
    case publicHolidays:
      return {
        ...state,
        publicHolidaysData: action.payload

      }
    case nameFilter:
      return {
        ...state,
        employeeFilter: action.payload,
      };
    case yearFilter:
      return {
        ...state,
        yearFilter: action.payload,
      };

    case updateEmployees:
      return {
        ...state,
        employees: action.payload,
      };

    case updateIsAddEmployeeClicked:
      return {
        ...state,
        isAddEmployeeClicked: action.payload,
      };

    case updateIsEditEmployeeClicked:
      return {
        ...state,
        isEditEmployeeClicked: action.payload,
      };
    case updateEmployeesDataTable:
      return {
        ...state,
        employeesDataTable: action.payload,
      };

    case updateEmployeesLeavesDataTable:
      return {
        ...state,
        employeesLeavesDataTable: action.payload,
      };

    case leaveSatus:
      return {
        ...state,
        leavesStatus: action.payload
      }

    case updateEmployeesFilteredLeavesDataTable:
      return {
        ...state,
        filterLeavesRows: action.payload,
      };

    case leaveApplications:
      return {
        ...state,
        leaveApplicationsData: action.payload
      }

    case updateItemInLeaves:
      const rows = [];
      let isNew = true;
      state.employeesLeavesDataTable.rows.filter(item => {
        if (item.id == action.payload.id) {
          isNew = false;
          rows.push({
            ...action.payload.item
          })
        }
        else {
          rows.push(item)
        }
      })

      if (isNew) {
        rows.push({
          ...action.payload.item
        })
      }
      return {
        ...state,
        employeesLeavesDataTable: { ...state.employeesLeavesDataTable, rows: rows },
      }

    case deleteItemInLeaves:
      const LeaveRows = [];
      state.employeesLeavesDataTable.rows.filter(item => {
        if (item.id != action.payload) {

          LeaveRows.push(item)
        }
      })


      return {
        ...state,
        employeesLeavesDataTable: { ...state.employeesLeavesDataTable, rows: LeaveRows },
      }


    case updateIsViewEmpClicked:
      return {
        ...state,
        isViewEmpClicked: action.payload,
      };

    case updateIsViewLeaveClicked:
      return {
        ...state,
        isViewLeaveClicked: action.payload,
      };
    case updateIsAddLeaveTypeClicked:
      return {
        ...state,
        isAddLeaveTypeClicked: action.payload,
      };
    case updateIsEditLeaveTypeClicked:
      return {
        ...state,
        isEditLeaveTypeClicked: action.payload,
      };
    case updateNewLeaveType:
      return {
        ...state,
        newType: action.payload,
      };
    case updateLeavesTypeDataTable:
      return {
        ...state,
        leavesTypeDataTable: action.payload,
      };


    case setActiveKey:
      return {
        ...state,
        activeKey: action.payload,
      };

    case updateLeaveType:
      return {
        ...state,
        leaveType: action.payload,
      };

    case updatePublicHolidayDataTable:
      return {
        ...state,
        publicHolidaysDataTable: action.payload,
      };

      case updateEmployeeOnLeaveDataTable:
        return{
          ...state,
          employeeOnLeaveDataTable: action.payload
        }

    default:
      return state;
  }

};

export default reducer;
