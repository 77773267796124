import { CBadge } from "@coreui/react";
import moment from "moment";

export const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderBottom: '1px dotted gray',
        color: state.isSelected ? 'white' : 'black',
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
        return { ...provided, opacity, transition };
    }
};

export const candidateType = [
    { value: "Fresh", label: "Fresh", field: "phase" },
    { value: "Experience", label: "Experience", field: "phase" },
    { value: "Expert", label: "Expert", field: "phase" },
  ];

export const ticketStatusColors = {
    "In Review": "#3399FF",
    "In Progress": "#E59F14",
    "Resolved": "green",
    "Declined": "#E55353",
};

export const ticketColumn = [
    {
        label: "#",
        field: "ticket_no",
        width: 200,
    },
    {
        label: "Priority",
        field: "priority",
        width: 100,
    },
    {
        label: "Title",
        field: "title",
        width: 200,
    },
    {
        label: "Assigned To",
        field: "assigned",
        width: 120,
      },
    {
        label: "Category",
        field: "category",
        width: 200,
    },
    {
        label: "Date",
        field: "date",
        width: 100,
    },
    {
        label: "Status",
        field: "status",
        width: 200,
    },
    {
        label: "Actions",
        field: "action",
        width: 200,
    },
];

export const getPublicHolidays = (holidays, year) => {
    const publicLeaves = [];
    holidays && holidays.filter(item => {
        item.publicHolidays.map(ph => {
            // if (!year || (year && moment(ph).format("YYYY") == year)) {
                publicLeaves.push(moment(ph).format("YYYY-MM-DD"));
            // }
        })
    })
    return publicLeaves;
}



export const getPublicYearlyHolidays = (holidays, year) => {
    const publicLeaves = [];
    holidays && holidays.filter(item => {
        item.publicHolidays.map(ph => {
            if (!year || (year && moment(ph).format("YYYY") == year)) {
                publicLeaves.push(moment(ph).format("YYYY-MM-DD"));
            }
        })
    })
    return publicLeaves;
}


export const getToken=() =>{return  JSON.parse(window.localStorage.getItem('currentUser')) || null};
export const LeavesColors = {
    "Short": "#F9B115",
    "Half Day": "blue",
    "Full Day": "green",
    "Applied": "#4F5D73"
}

export const levaesMapAttendance = (leaves, attendance) => {
    var employeeOnLeave=false;
    var type='';
        leaves.map(row => {
          if (row.ApplicationStatus == "Approved") {
            row.LeavesDates.filter((r) => {
              if (!attendance.checkin && !attendance.checkout) {
                if (moment(r).format("DD MM YYYY") == moment(attendance.date).format("DD MM YYYY")) {
                  employeeOnLeave=true;
                  type=row.LeaveType
                }
              }
            })
          }
    
        })
    return [employeeOnLeave,type];
    
      }

export const AvailedLeavesCalculations = (leaves) => {
    var allowedLeaves = 0;
    leaves.map(leave => {
        if (leave.category != "Unpaid Leaves" && leave.ApplicationStatus != "Applied") {
            if (leave.type == "Full Day") {
                allowedLeaves += leave.Leaves.length;
            }
            if (leave.type == "Half Day") {
                const half = leave.Leaves.length / 2
                allowedLeaves += half;
            }
            if (leave.type == "Short") {
                const short = leave.Leaves.length / 4;
                allowedLeaves += short;
            }
        }
    })
    return allowedLeaves;
}

export const allowedLeaves = (leaveTypes) => {
    console.log("LEAVES S S S",leaveTypes)
    const personalLeaves = leaveTypes?.personal_leaves || 0;
    let employeeAllowedLeaves = parseFloat(personalLeaves);
    if (leaveTypes?.compassionate_leave) {
        employeeAllowedLeaves += parseFloat(leaveTypes?.compassionate_leave);
    }
    if(leaveTypes?.compensation_leave){
        employeeAllowedLeaves+=parseFloat(leaveTypes?.compensation_leave)
    }
    if (leaveTypes?.service_leave) {
        employeeAllowedLeaves += parseFloat(leaveTypes?.service_leave);
    }
    if (leaveTypes?.paternity_leaves) {
        employeeAllowedLeaves += parseFloat(leaveTypes?.paternity_leaves);
    }
    if (leaveTypes?.bereavement_leave) {
        employeeAllowedLeaves += parseFloat(leaveTypes?.bereavement_leave);
    }
    return parseFloat(employeeAllowedLeaves).toFixed(1);
}

export const leaveQuota = (leaveTypes, leaves, year) => {
    const isCurrentYear = moment().format("YYYY") == year;
    const availed = AvailedLeavesCalculations(leaves)
    const employeeAllowedLeaves = allowedLeaves(leaveTypes);
    const currentMonth = isCurrentYear ? moment().format("MM") : 12;
    const allowedPerMonth = employeeAllowedLeaves / 12;
    const allwedTillCurrentMonth = currentMonth * allowedPerMonth;
    return parseFloat(allwedTillCurrentMonth - availed).toFixed(1);
}

export const Options = [
    { value: "Excellent", label: "Excellent", score: 90 },
    { value: "Very Good", label: "Very Good", score: 85 },
    { value: "Good", label: "Good", score: 75 },
    { value: "Above Average", label: "Above Average", score: 65 },
    { value: "Average", label: "Average", score: 50 },
    { value: "Below Average", label: "Below Average", score: 40 },
    { value: "Fair", label: "Fair", score: 35 },
    { value: "Poor", label: "Poor", score: 25 },
    { value: "Very Poor", label: "Very Poor", score: 10 },

];


export const evaluationMonthFilter = [
    { label: "Last 1 Month", value: 2 },
    { label: "Last 3 Months", value: 3 },
    { label: "Last 6 Months", value: 6 },
    { label: "Last 9 Months", value: 9 },
    { label: "Last 1 Year", value: 12 },
    { label: "Last 2 Years", value: 24 },
];

export const evaluationsScores = {
    "Excellent": 90,
    "Very Good": 85,
    "Good": 75,
    "Above Average": 65,
    "Average": 50,
    "Below Average": 40,
    "Fair": 35,
    "Poor": 25,
    "Very Poor": 10,
    "Terrible": 5
};


export const LeaveCategory = (employeeLeaveType) => {
    const leavesOptions = [
        {
            label: 'Personal leave (Casual + Sick)',
            value: 'Personal leave (Casual + Sick)'
        },
        {
            label: 'Unpaid Leaves',
            value: 'Unpaid Leaves'
        },
        {
            label: 'Others',
            value: 'Others'
        }
    ];

    if (employeeLeaveType.compassionate_leave && parseFloat(employeeLeaveType.compassionate_leave) > 0) {
        leavesOptions.push({
            label: 'Compassionate Leave',
            value: 'Compassionate Leave'
        })
    }
    if (employeeLeaveType.compassionate_leave && parseFloat(employeeLeaveType.compassionate_leave) > 0) {
        leavesOptions.push({
        label: 'Public holiday compensation',
        value: 'Public holiday compensation'
    })
}

    if (employeeLeaveType.service_leave && parseFloat(employeeLeaveType.service_leave) > 0) {
        leavesOptions.push({
            label: 'Long Service Leave',
            value: 'Long Service Leave'
        })
    }
    if (employeeLeaveType.compensation_leave && parseFloat(employeeLeaveType.compensation_leave) > 0) {
        leavesOptions.push({
            label: 'Compensation Leave',
            value: 'Compensation Leave'
        })
    }

    if (employeeLeaveType.paternity_leaves && parseFloat(employeeLeaveType.paternity_leaves) > 0) {
        leavesOptions.push({
            label: 'Paternity Leave',
            value: 'Paternity Leave'
        })
    }

    if (employeeLeaveType.bereavement_leave && parseFloat(employeeLeaveType.bereavement_leave) > 0) {
        leavesOptions.push({
            label: 'Bereavement Leave',
            value: 'Bereavement Leave'
        })
    }

    return leavesOptions;
}

export const ScoreCalculation = (row, evaluationList) => {
    if (row.score >= 85) {
        evaluationList.great++
    }
    if (row.score < 85 && row.score >= 70) {
        evaluationList.good++
    }
    if (row.score >= 55 && row.score < 70) {
        evaluationList.avg++
    }
    if (row.score >= 35 && row.score < 55) {
        evaluationList.fair++
    }
    if (row.score < 35) {
        evaluationList.poor++
    }
    return evaluationList;
}

export   const breakTimeCalculation = (breakin, breakout) => {
    const breaks = [];
    // var breakTime=0;
    breakin.map((x, index) => {
      const breakTime =
        breakout[index] && x
          ? moment(breakout[index]).diff(moment(x), "seconds")
          : 0;

      breaks.push(
        <div>
          <CBadge color="info" style={{ margin: "2px" }}>
            {moment.utc(breakTime * 1000).format("HH:mm:ss")}
            {/* {parseInt(breakTime / 60) + ":" + (breakTime % 60)} */}
          </CBadge>
        </div>
      );
    });

    return breaks;
  };

export const hoursCalculations = (x, breakins, breakouts) => {
    const hours =
      x.checkout && x.checkin
        ? moment(x.checkout).diff(moment(x.checkin), "minutes")
        : 0;

    var breakTime = 0;
    breakins.map((x, index) => {
      breakTime +=
        breakouts[index] && x
          ? moment(breakouts[index]).diff(moment(x), "minutes")
          : 0;
    });

    const reminaingHours = hours - breakTime;
    return parseInt(reminaingHours / 60) + ":" + (reminaingHours % 60);
  };

  export const isPublicHoliday = (publicLeaves,date) => {
    const formattedDate = moment(date).format("MM/DD/YYYY");
    return publicLeaves?.some((holiday) =>
      holiday.publicHolidays.includes(formattedDate)
    );
  };

  export  const TableOptions = {
    filter: false,
    serverSide: true,
    selectableRows: false,
    search: false,
    rowsPerPage: 100,
    rowsPerPageOptions: [],
    pagination:false
   

  };

  export const formatCNIC=(cnic)=> {
    // Remove any non-numeric characters from the input string
    const numericCNIC = cnic.replace(/\D/g, '');
  
    // Check if the numericCNIC is empty or doesn't match the expected length
    if (numericCNIC.length !== 13) {
      return 'Invalid CNIC';
    }
  
    // Format the CNIC as per the standard format
    const formattedCNIC = `${numericCNIC.slice(0, 5)}-${numericCNIC.slice(5, 12)}-${numericCNIC.slice(12)}`;
  
    return formattedCNIC;
  }

  export const  formatPhoneNumber=(phoneNumber) =>{
    // Remove any non-numeric characters from the input string
    const numericPhoneNumber = phoneNumber.replace(/\D/g, '');
  
    // Check if the numericPhoneNumber is empty or doesn't match the expected length
    if (numericPhoneNumber.length !== 11) {
      return 'Invalid phone number';
    }
  
    // Format the phone number as per the standard format
    const formattedPhoneNumber =
      numericPhoneNumber.slice(0, 4) + '-' + numericPhoneNumber.slice(4, 7) + '-' + numericPhoneNumber.slice(7);
  
    return formattedPhoneNumber;
  }
  
  export const  AttendanceColumns= [{
    label: "Name",
    name: "name",
    options: {
        filter: false,
        sort: false,
      },
  },
  {
    label: "Category",
    name: "category",
    options: {
        filter: false,
        sort: false,
      },
  },
  {
    label: "Type",
    name: "leaveTypeShow",
    options: {
        filter: false,
        sort: false,
      },
  },
  {
    label: "Leave Reason",
    name: "Reason",
    options: {
        filter: false,
        sort: false,
      },
  },


  {
    label: "Dates",
    name: "daysLeaves",
    options: {
        filter: false,
        sort: false,
      },
  },
 
  {
    label: "Status",
    name: "ApplicationStatusShow",
    options: {
        filter: false,
        sort: false,
      },
  },
  {
    label: "Comment",
    name: "rejection_reason",
    options: {
        filter: false,
        sort: false,
      },
  },
  {
    label: "Action",
    name: "action",
    options: {
        filter: false,
        sort: false,
      },
  },
  ];