import {
  updateNewEmployeeEvaluation,
  updateIsAddEmployeeEvaluationClicked,
  updateEmployeesEvaluation,
  updateIsEditEmployeeEvaluationClicked,
  updateEmployeesEvaluationDataTable,
  updateIsViewEmpEvaluationClicked,
  updateIsAddMemberDetailsClicked,
  updateIsAddTeamDetailsClicked,
  addEmployeeSelfEvaluations,
  isAddSelfClicked,
  updateEmployeeSelfEvaluations,
  setEvaluationResults,
  starOfTheMonthEmployee,
  updateIsEditHrEvaluationClicked,
  updateHrEvaluationDataTable,
  updateIsAddHrEvaluationClicked,
  addHrSelfEvaluations,
  updateHrSelfEvaluations,
  setSelectedTeam,
  updateHrEvaluation,
  setEvaluationHRResults,
} from "./employeeEvaluation.types";
import { ScoreCalculation } from "src/utils/common";

const INITIAL_STATE = {
  employeesEvaluation: [],
  hrEvaluation: [],
  newEvaluation: {},
  selectedTeam: {},
  isAddEmployeeEvaluationClicked: null,
  isEditEmployeeEvaluationClicked: null,
  isAddMemberDetails: [],
  isAddTeamDetails: [],
  employeesEvaluationDataTable: {
    columns: [{
      label: "Employee Name",
      field: "employeeName",
      width: 270,
    },
    {
      label: "Month",
      field: "month_evaluation",
      width: 200,
    },
    {
      label: "Team Lead",
      field: "teamLeadName",
      width: 200,
    },
    {
      label: "Team",
      field: "teamname",
      width: 100,

    },

    {
      label: "Lead",
      field: "score",
      width: 100,
    },
    {
      label: "Self Lead",
      field: "score_self",
      width: 100,
    },
    {
      label: "HR",
      field: "score_hr",
      width: 100,
    },
    {
      label: "Self HR",
      field: "score_self_hr",
      width: 100,
    },


    {
      label: "Action",
      field: "action",
      width: 100,
    },
    ],
    rows: [],
  },
  isAddHrEvaluationClicked: null,
  isEditHrEvaluationClicked: null,
  selfHrEvaluations: [],
  isViewEmpEvaluationClicked: null,
  selfEmployeeEvaluations: [],
  isAddSelfClicked: false,
  evaluationResults: {
    great: 0,
    good: 0,
    avg: 0,
    fair: 0,
    poor: 0,
  },
  evaluationHRResults: {
    great: 0,
    good: 0,
    avg: 0,
    fair: 0,
    poor: 0,
  },
  starOfTheMonth: [],
};
import moment from "moment";

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateNewEmployeeEvaluation:
      return {
        ...state,
        newEvaluation: action.payload,
      };

    case setSelectedTeam:
      return {
        ...state,
        selectedTeam: action.payload,
      };

    case addEmployeeSelfEvaluations:
      return {
        ...state,
        selfEmployeeEvaluations: action.payload,
      };
    case addHrSelfEvaluations:
      return {
        ...state,
        selfHrEvaluations: action.payload,
      };

    case setEvaluationResults:
      return {
        ...state,
        evaluationResults: action.payload,
      };

    case setEvaluationHRResults:
      return {
        ...state,
        evaluationHRResults: action.payload,
      };

    case updateEmployeeSelfEvaluations:
      let evaluationList = { ...state.evaluationResults };
      let temp = [...state.selfEmployeeEvaluations];

      temp.map((evaluation) => {
        if (
          moment(evaluation.month).format("MMM YYYY") ===
          moment(action.payload.month).format("MMM YYYY") &&
          action.payload.employeeId == evaluation.employeeId &&
          action.payload.teamId == evaluation.teamId
        ) {
          ScoreCalculation(evaluation, evaluationList);
          evaluation.selfofMonth = action.payload;
        }
      });

      return {
        ...state,
        selfEmployeeEvaluations: temp,
        evaluationResults: evaluationList,
      };
    case updateHrSelfEvaluations:
      
      let hrEvaluationList = { ...state.evaluationResults };
      let tempHr = [...state.selfHrEvaluations];

      tempHr.map((evaluation) => {
        if (
          moment(evaluation.month).format("MMM YYYY") ===
          moment(action.payload.month).format("MMM YYYY") &&
          action.payload.employeeId == evaluation.employeeId &&
          action.payload.teamId == evaluation.teamId
        ) {

          ScoreCalculation(evaluation, hrEvaluationList);
          evaluation.selfofMonth = action.payload;

        }
      });

      return {
        ...state,
        selfHrEvaluations: tempHr,
        evaluationResults: hrEvaluationList,
      };


    case isAddSelfClicked:
      return {
        ...state,
        isAddSelfClicked: action.payload,
      };

    case updateEmployeesEvaluation:
      return {
        ...state,
        employeesEvaluation: action.payload,
      };
    case updateHrEvaluation:
      return {
        ...state,
        hrEvaluation: action.payload,
      };
    case updateIsAddEmployeeEvaluationClicked:
      return {
        ...state,
        isAddEmployeeEvaluationClicked: action.payload,
      };
    case updateIsAddHrEvaluationClicked:
      return {
        ...state,
        isAddHrEvaluationClicked: action.payload,
      };
    case updateIsEditHrEvaluationClicked:
      return {
        ...state,
        isEditHrEvaluationClicked: action.payload,
      };
    case updateHrEvaluationDataTable:
      return {
        ...state,
        hrEvaluationDataTable: action.payload,
      };
    case updateIsEditEmployeeEvaluationClicked:
      return {
        ...state,
        isEditEmployeeEvaluationClicked: action.payload,
      };
    case updateEmployeesEvaluationDataTable:
      return {
        ...state,
        employeesEvaluationDataTable: action.payload,
      };
    case updateIsViewEmpEvaluationClicked:
      return {
        ...state,
        isViewEmpEvaluationClicked: action.payload,
      };
    case updateIsAddMemberDetailsClicked:
      return {
        ...state,
        isAddMemberDetails: action.payload,
      };

    case updateIsAddTeamDetailsClicked:
      return {
        ...state,
        isAddTeamDetails: action.payload,
      };

    case starOfTheMonthEmployee:
      return {
        ...state,
        starOfTheMonth: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;