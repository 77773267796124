import React ,{useEffect} from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./css/style.css";
import { ToastContainer,toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

import '@coreui/coreui/dist/css/coreui.min.css'
import { employeeRequests } from "./API/EmployeeApi";
import { publicHolidaysAction } from "./redux/Employees/employees.actions";
import { useDispatch } from "react-redux";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));
const TimeLayout = React.lazy(() => import("./layout/TimeLayout"));


// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));





const App =()=> {
  useEffect(() => {
    handleGetEmployeeApi();
  }, []);
  const dispatch = useDispatch();

	
  const handleGetEmployeeApi = async () => {
    const publicHolidays = await employeeRequests.getPublicHolidaysApi();
    if (publicHolidays.error) {
      // toast.error("Public Holidays GET ERROR" + publicHolidays.data);
    } else {
      dispatch(publicHolidaysAction(publicHolidays.data));
    }}

  
    return (
      <>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
                <Route
                path="/timeManagement"
                name="TimeManagement"
								render={(props) =>
										<TimeLayout {...props}/>
									}
              />
               <Route
                path="/employeeTime"
                name="EmployeeTime"
								render={(props) =>
										<TimeLayout {...props}/>
									}
              />
              <Route
                path="/"
                name="Home"
								render={(props) =>
										<DefaultLayout {...props}/>
									}
              />
             
            </Switch>
          </React.Suspense>
        </HashRouter>
        <ToastContainer />
      </>
    );
}

export default App;
