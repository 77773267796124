import {
    updateNewProject,
    updateIsAddProjectClicked,
    updateProject,
    updateIsEditProjectClicked,
    updateProjectsDataTable,
    updateIsViewProjectClicked
} from "./projects.types";

const INITIAL_STATE = {
    projects: [],
    newProjects: {},
    isAddProjectClicked: null,
    isEditProjectClicked: null,
    projectsDataTable: {
        columns: [{
                label: "Title",
                field: "title",
                width: 270,
            },
            {
                label: "Type",
                field: "project_type",
                width: 200,
            },
            {
                label: "Description",
                field: "description",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                width: 100,
            },
        ],
        rows: [],
        isViewProjectClicked: null
    },
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case updateNewProject:
            return {
                ...state,
                newProjects: action.payload,
            };

        case updateProject:
            return {
                ...state,
                projects: action.payload,
            };

        case updateIsAddProjectClicked:
            return {
                ...state,
                isAddProjectClicked: action.payload,
            };

        case updateIsEditProjectClicked:
            return {
                ...state,
                isEditProjectClicked: action.payload,
            };

        case updateProjectsDataTable:
            return {
                ...state,
                projectsDataTable: action.payload,
            };
        case updateIsViewProjectClicked:
            return {
                ...state,
                isViewProjectClicked: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;