import { updateInterviewDataTableAction } from "./candidates.actions";
import {
    updateNewCandidate,
    updateIsAddCandidateClicked,
    updateCandidates,
    updateIsEditCandidateClicked,
    updateCandidatesDataTable,
    updateIsViewCandidateClicked,
    updateNewCandidateInterview,
    updateInterview,
    updateInterviewDataTable,

} from "./candidates.types";

const INITIAL_STATE = {
    candidates: [],
    newCandidate: {},
    isAddCandidateClicked: null,
    isEditCandidateClicked: null,
    candidatesDataTable: {
        columns: [{
                label: "Candidate Name",
                field: "candidateName",
                width: 270,
            },
            {
                label: "Post Applied For",
                field: "positionName",
                width: 200,
            },
            // {
            //     label: "Experience",
            //     field: "positionName",
            //     width: 200,
            // },
            {
                label: "Current Salary",
                field: "currentSalary",
                width: 200,
            },
            {
                label: "Expected Salary",
                field: "expectedSalary",
                width: 200,
            },
            {
                label: "Communication",
                field: "verbalCommunicationRating",
                width: 200,
            },
            {
                label: "Status",
                field: "status",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                width: 100,
            },
        ],
        rows: [],
    },
    isViewCandidateClicked: null,
    newInterview: {},
    interviewsDataTable: {
        columns: [{
                label: "Candidate Name",
                field: "candidateName",
                width: 270,
            },
            {
                label: "Interviewer",
                field: "interviewer",
                width: 200,
            },
            {
                label: "Interview Date",
                field: "interviewDate",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                width: 200,
            },

        ],
        rows: [],
    },

};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case updateNewCandidate:
            return {
                ...state,
                newCandidate: action.payload,
            };

        case updateCandidates:
            return {
                ...state,
                candidates: action.payload,
            };

        case updateIsAddCandidateClicked:

            return {
                ...state,
                isAddCandidateClicked: action.payload,
            };

        case updateIsEditCandidateClicked:
            return {
                ...state,
                isEditCandidateClicked: action.payload,
            };
        case updateCandidatesDataTable:
            return {
                ...state,
                candidatesDataTable: action.payload,
            };
        case updateIsViewCandidateClicked:
            return {
                ...state,
                isViewCandidateClicked: action.payload,
            };
            case updateNewCandidateInterview:
                return {
                    ...state,
                    newInterview: action.payload,
                };
                case updateInterview:
                    return {
                        ...state,
                        interview: action.payload,
                    };
                    case updateInterviewDataTable:
                        return {
                            ...state,
                            interviewsDataTable: action.payload,
                        };
        default:
            return state;
    }
};

export default reducer;