import {
  updateIsViewTimeClicked,
  updateTimeDataTable,
  updateIsEditTimeClicked,
  updateIsAddTimeClicked,
  updateTime,
  updateNewTime,
} from "./time.types";

const INITIAL_STATE = {
  time: [],
  newTime: {},
  isAddTimeClicked: null,
  isEditTimeClicked: null,
  timeDataTable: {
    columns: [
      {
        label: "Date",
        field: "date",
        width: "370px",
      },
      {
        label: "Project",
        field: "project",
        width: 200,
      },
      {
        label: "Task",
        field: "task_title",
        width: 200,
      },
      {
        label: "Notes",
        field: "notes",
        width: 200,
      },
    ],
    rows: [],
    isViewTimeClicked: null,
  },
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case updateNewTime:
      return {
        ...state,
        newTime: action.payload,
      };

    case updateTime:
      return {
        ...state,
        time: action.payload,
      };

    case updateIsAddTimeClicked:
      return {
        ...state,
        isAddTimeClicked: action.payload,
      };

    case updateIsEditTimeClicked:
      return {
        ...state,
        isEditTimeClicked: action.payload,
      };

    case updateTimeDataTable:
      return {
        ...state,
        timeDataTable: action.payload,
      };
    case updateIsViewTimeClicked:
      return {
        ...state,
        isViewTimeClicked: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
