import axios from "axios";
import moment from "moment";
import { apiUrl, headers } from "../constant";
import { getToken } from '../utils/common'

class employeeApi {
  addEmployeeApi = async (newEmployee) => {
    return axios({
      method: "post",
      url: apiUrl + `/api/v1/employees`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
      data: {
        name: newEmployee.name,
        gender: newEmployee.gender,
        dateOfBirth: newEmployee.dateOfBirth,
        education: newEmployee.education ? newEmployee.education : "N/A",
        email: newEmployee.email,
        joiningDate: newEmployee.joiningDate,
        designation: newEmployee.designation,
        address: newEmployee.address,
        phoneNumber: newEmployee.phoneNumber,
        salary: newEmployee.salary ? newEmployee.salary : "0000",
        permanentDate:
          newEmployee.permanentDate == "Same"
            ? newEmployee.joiningDate
            : newEmployee.permanentDate,
        status: newEmployee.status,
        appointmentLetterStatus: newEmployee.appointmentLetterStatus === "true",
        agreementSignStatus: newEmployee.agreementSignStatus === "true",
        linkedInProfile: newEmployee.linkedInProfile
          ? newEmployee.linkedInProfile
          : "N/A",
        personalEmail: newEmployee.personalEmail,
        cnic: newEmployee.cnic,
        employee_No: newEmployee.employee_No,
        workExperience: newEmployee.workExperience
          ? newEmployee.workExperience
          : "0",
        technology: newEmployee.technology ? newEmployee.technology : "N/A",
        profile_url: newEmployee.profile_url ? newEmployee.profile_url : "",
        personal_leaves: newEmployee.personal_leaves ? newEmployee.personal_leaves : 0,
        compassionate_leave: newEmployee.compassionate_leave ? newEmployee.compassionate_leave.toString() === "true" : false,
        service_leave: newEmployee.service_leave ? newEmployee.service_leave.toString() === "true" : false,
        paternity_leaves: newEmployee.paternity_leaves ? newEmployee.paternity_leaves.toString() === "true" : false,
        bereavement_leave: newEmployee.bereavement_leave ? newEmployee.bereavement_leave.toString() === "true" : false,
        compensation_leave: newEmployee.compensation_leave ? newEmployee.compensation_leave.toString() === "true" : false,
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        const error = JSON.stringify(err);
        console.log("Employee Error", error);
        return {
          error: true,
          data: err,
        };
      });
  };

  addBulkEmployeeApi = async (newEmployee) => {
    return axios({
      method: "post",
      url: apiUrl + `/api/v1/employees/bulkCreate`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
      data: {
        name: newEmployee.name,
        gender: newEmployee.gender,
        dateOfBirth: newEmployee.dateOfBirth,
        education: newEmployee.education ? newEmployee.education : "N/A",
        email: newEmployee.email,
        joiningDate: newEmployee.joiningDate,
        designation: newEmployee.designation,
        address: newEmployee.address,
        phoneNumber: newEmployee.phoneNumber,
        salary: newEmployee.salary ? newEmployee.salary : "0000",
        permanentDate:
          newEmployee.permanentDate == "Same"
            ? newEmployee.joiningDate
            : newEmployee.permanentDate,
        status: newEmployee.status,
        appointmentLetterStatus: newEmployee.appointmentLetterStatus === "true",
        agreementSignStatus: newEmployee.agreementSignStatus === "true",
        linkedInProfile: newEmployee.linkedInProfile
          ? newEmployee.linkedInProfile
          : "N/A",
        personalEmail: newEmployee.personalEmail,
        cnic: newEmployee.cnic,
        employee_No: newEmployee.employee_No,
        workExperience: newEmployee.workExperience
          ? newEmployee.workExperience
          : "0",
        technology: newEmployee.technology ? newEmployee.technology : "N/A",
        profile_url: newEmployee.profile_url ? newEmployee.profile_url : "",
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        const error = JSON.stringify(err);
        console.log("Employee Error", error);
        return {
          error: true,
          data: err,
        };
      });
  };

  deleteLeaves(id,title) {
    return axios({
      method: "delete",
      url: apiUrl + `/api/v1/employeeLeaves/${id}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
      data: {title},
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  }

  updateEmployeeLeavesApi = async (leaves,title) => {
    return axios({
      method: "post",
      url: apiUrl + `/api/v1/employeeLeaves`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
      data: {
        id: leaves.id,
        EmployeeId: leaves.EmployeeId,
        ApplicationDate: moment(),
        LeavesDates: leaves.LeavesDates.sort(),
        category: leaves.category,
        LeaveType: leaves.LeaveType,
        ApplicationStatus: leaves.ApplicationStatus,
        Reason: leaves.Reason,
        rejection_reason: leaves.rejection_reason,
        title
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        const error = JSON.stringify(err);
        console.log("Employee Error", error);
        return {
          error: true,
          data: err,
        };
      });
  };

  addEmployeeLeavesApi = async (leaves,title) => {
    return axios({
      method: "post",
      url: apiUrl + `/api/v1/employeeLeaves`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
      data: {
        EmployeeId: leaves.EmployeeId,
        ApplicationDate: moment(),
        LeavesDates: leaves.LeavesDates.sort(),
        category: leaves.category,
        LeaveType: leaves.LeaveType,
        ApplicationStatus: leaves.ApplicationStatus,
        Reason: leaves.Reason,
        rejection_reason: leaves.rejection_reason,
        title
        
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        const error = JSON.stringify(err);
        console.log("Employee Error", error);
        return {
          error: true,
          data: err,
        };
      });
  };

  getEmployeesLeavesApi = async (id) => {
    return axios({
      method: "get",
      url: apiUrl + `/api/v1/employeeLeaves/${id}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  getLeaveByIdApi = async (id) => {
    return axios({
      method: "get",
      url: apiUrl + `/api/v1/employeeLeaves/leave/${id}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  getAllEmployeesLeavesApi = async () => {
    return axios({
      method: "get",
      url: apiUrl + `/api/v1/employeeLeaves`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  getLeaveApplicationsApi = async () => {
    return axios({
      method: "get",
      url: apiUrl + `/api/v1/employeeLeaves/applications`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  getPublicHolidaysApi = async () => {
    return axios({
      method: "get",
      url: apiUrl + `/api/v1/public-holidays`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  addPublicHolidaysApi = async (data) => {

    return axios({
      method: "post",
      url: apiUrl + `/api/v1/public-holidays`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
      data: {
        title: data.title,
        year: data.year,
        publicHolidays: data.LeavesDates.sort()

      }
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  updatePublicHolidaysApi = async (id, data) => {

    return axios({
      method: "patch",
      url: apiUrl + `/api/v1/public-holidays/${id}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
      data: {
        title: data.title,
        year: data.year,
        publicHolidays: data.LeavesDates.sort()
      }
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  deletePublicHolidaysApi = async (id) => {

    return axios({
      method: "delete",
      url: apiUrl + `/api/v1/public-holidays/${id}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },

    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  deleteEmployeeApi = async (id) => {
    return axios({
      method: "delete",
      url: apiUrl + `/api/v1/employees/${id}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  getEmployeesApi = async () => {
    return axios({
      method: "get",
    url: apiUrl + `/api/v1/employees`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  getEmployeeApi = async (id,page,limit) => {
    return axios({
      method: "get",
      url: apiUrl + `/api/v1/employees/${id}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  updateEmployeeApi = async (newEmployee) => {
    return axios({
      method: "patch",
      url: apiUrl + `/api/v1/employees/${newEmployee.id}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
      data: {
        name: newEmployee.name,
        gender: newEmployee.gender,
        dateOfBirth: newEmployee.dateOfBirth,
        education: newEmployee.education,
        email: newEmployee.email,
        joiningDate: newEmployee.joiningDate,
        designation: newEmployee.designation,
        address: newEmployee.address,
        phoneNumber: newEmployee.phoneNumber,
        salary: newEmployee.salary,
        permanentDate: newEmployee.permanentDate,
        status: newEmployee.status,
        appointmentLetterStatus: newEmployee.appointmentLetterStatus === "true",
        agreementSignStatus: newEmployee.agreementSignStatus === "true",
        linkedInProfile: newEmployee.linkedInProfile,
        personalEmail: newEmployee.personalEmail,
        cnic: newEmployee.cnic,
        employee_No: newEmployee.employee_No,
        profile_url: newEmployee.profile_url,
        personal_leaves: newEmployee.personal_leaves ? newEmployee.personal_leaves : 0,
        compassionate_leave: newEmployee.compassionate_leave ? newEmployee.compassionate_leave.toString() === "true" : false,
        service_leave: newEmployee.service_leave ? newEmployee.service_leave.toString() === "true" : false,
        paternity_leaves: newEmployee.paternity_leaves ? newEmployee.paternity_leaves.toString() === "true" : false,
        bereavement_leave: newEmployee.bereavement_leave ? newEmployee.bereavement_leave.toString() === "true" : false,
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  getEmployeeWorkHistory = async (empId) => {
    return axios({
      method: "get",
      url: apiUrl + `/api/v1/employees/work_history/${empId}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  addLeavesTypesApi = async (newType, id) => {
    newType.employeeid = id;
    return axios({
      method: "post",
      url: apiUrl + `/api/v1/leaves-types`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },

      data: {
        ...newType
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        const error = JSON.stringify(err);
        console.log("Leave Error", error);
        return {
          error: true,
          data: err,
        };
      });
  };
  updateLeavesTypesApi = async (newType) => {
    return axios({
      method: "patch",
      url: apiUrl + `/api/v1/leaves-types/${newType.id}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
      data: {
        personal_leaves: newType.personal_leaves,
        compassionate_leave: newType.compassionate_leave,
        service_leave: newType.service_leave,
        paternity_leaves: newType.paternity_leaves,
        bereavement_leave: newType.bereavement_leave,
        compensation_leave: newType.compensation_leave,
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        const error = JSON.stringify(err);
        console.log("Leave Error", error);
        return {
          error: true,
          data: err,
        };
      });
  };
  deleteLeavesTypesApi = async (id) => {
    return axios({
      method: "delete",
      url: apiUrl + `/api/v1/leaves-types/${id}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  getEmployeeLeavesTypesApi = async (id) => {
    return axios({
      method: "get",
      url: apiUrl + `/api/v1/leaves-types/employee/${id}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  getLeavesTypesApi = async () => {
    return axios({
      method: "get",
      url: apiUrl + `/api/v1/leaves-types`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  getAllEmployeesOnLeave = async () => {
    return axios({
      method: "get",
      url: apiUrl + `/api/v1/employeeLeaves/approved`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

  getEmployeeEmailValidationApi = async (email) => {
    return axios({
      method: "post",
      url: apiUrl + `/api/v1/employees/email-validation`,
      headers: {
        ...headers,
        Authorization: `Bearer ${getToken()?.access_token}`
      },
      data: {
        email
      },
    })
      .then((result) => {
        return {
          error: false,
          data: result.data,
        };
      })
      .catch((err) => {
        return {
          error: true,
          data: err,
        };
      });
  };

}
export let employeeRequests = new employeeApi();
