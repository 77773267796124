import {
    updateNewTask,
    updateIsAddTaskClicked,
    updateTask,
    updateIsEditTaskClicked,
    updateTasksDataTable,
    updateIsViewTaskClicked
} from "./task.types";

const INITIAL_STATE = {
    tasks: [],
    newTask: {},
    isAddProjectClicked: null,
    isEditTaskClicked: null,
    tasksDataTable: {
        columns: [{
                label: "Title",
                field: "task_name",
                width: 270,
            },
            {
                label: "Status",
                field: "task_status",
                width: 200,
            },
            {
                label: "Type",
                field: "task_type",
                width: 200,
            },
            {
                label: "Description",
                field: "description",
                width: 200,
            },
            {
                label: "Action",
                field: "action",
                width: 100,
            },
        ],
        rows: [],
        isViewTaskClicked: null
    },
};

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case updateNewTask:
            return {
                ...state,
                newTask: action.payload,
            };

        case updateTask:
            return {
                ...state,
                tasks: action.payload,
            };

        case updateIsAddTaskClicked:
            return {
                ...state,
                isAddTaskClicked: action.payload,
            };

        case updateIsEditTaskClicked:
            return {
                ...state,
                isEditTaskClicked: action.payload,
            };

        case updateTasksDataTable:
            return {
                ...state,
                tasksDataTable: action.payload,
            };
        case updateIsViewTaskClicked:
            return {
                ...state,
                isViewTaskClicked: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;